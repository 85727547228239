import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {UnauthenticatedService} from "./services/unauthenticated.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title, private breadcrumbService: BreadcrumbService, private unauthenticatedService: UnauthenticatedService) {
  }
  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });

    this.getSubdomain();
  }
  onActivate(event){
    window.scroll(0,0);
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'AgentIQ';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }
  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }

  getSubdomain(): void {
    const fullURL = window.location.host;
    const parts = fullURL.split('.');
    let subdomain = parts[0];
    const domain = parts[1];
    const type = parts[2];


    if (subdomain.includes('localhost')) {
      subdomain = 'goldmanteam';
    }

    this.getAgentDetails(subdomain);
  }
  getAgentDetails(subdomain): void {

    this.unauthenticatedService.getAgentDetails(subdomain).subscribe(data => {
      if (data && data[0]) {
        localStorage.setItem('tid', data[0].id);
        localStorage.setItem('tsd', subdomain);
      }
    }, error => {
      console.log(error);
    });

    // @ts-ignore
    // console.log(agents[subdomain]);
    // this.listings = listings[subdomain];
    // this.testimonial = testimonial[subdomain];

  }
}
