import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnauthenticatedService {

  // rootURL = 'http://localhost:8080';
  rootURL = 'https://api.agentiq.io';

  constructor(private httpClient: HttpClient) {}

  getAgentDetails(subdomain, emailCodeFromDomain?): Observable<any> {

    const URL = `${this.rootURL}/unauthenticated/team/${subdomain}`;
    var headers = {subdomain};

    if (emailCodeFromDomain) {
      // @ts-ignore
      headers.emailcode = emailCodeFromDomain;
    }
    // console.log(headers);
    /*return this.httpClient.get(URL,
      options: headers);*/


    return this.httpClient.request('get', URL,
      {
        headers
      }
    );

  }

  getStats(subdomain): Observable<any> {

    const URL = `${this.rootURL}/unauthenticated/stats/${subdomain}`;
    const headers = {subdomain};

    return this.httpClient.request('get', URL,
      {
        headers
      }
    );

  }

  getTestimonials(subdomain): Observable<any> {

    const URL = `${this.rootURL}/unauthenticated/testimonials/${subdomain}`;
    const headers = {subdomain};

    return this.httpClient.request('get', URL,
      {
        headers
      }
    );

  }

  getListings(subdomain): Observable<any> {

    const URL = `${this.rootURL}/unauthenticated/listings/${subdomain}`;
    const headers = {subdomain};

    return this.httpClient.request('get', URL,
      {
        headers
      }
    );

  }

  getLinks(subdomain): Observable<any> {

    const URL = `${this.rootURL}/unauthenticated/links/${subdomain}`;
    const headers = {subdomain};

    return this.httpClient.request('get', URL,
      {
        headers
      }
    );

  }

  getVideos(subdomain): Observable<any> {

    const URL = `${this.rootURL}/unauthenticated/videos/${subdomain}`;
    const headers = {subdomain};

    return this.httpClient.request('get', URL,
      {
        headers
      }
    );

  }

  getTrackingLink(subdomain, email): Observable<any> {

    const URL = `${this.rootURL}/email/link`;
    const headers = {subdomain};
    const body = {
      emailAddress: email
    };


    return this.httpClient.request('post', URL,
      {
        body,
        headers
      }
    );

  }

  requestContact(subdomain, body): Observable<any> {

    const URL = `${this.rootURL}/email/contact_form`;
    const headers = {subdomain};

    return this.httpClient.request('post', URL,
      {
        body,
        headers
      }
    );

  }

  updateTimeOnPage(subdomain): Observable<any> {

    const emailCode = localStorage.getItem('emailcode');
    const headers = {subdomain};
    const URL = `${this.rootURL}/email/update`;
    const body = {
      emailCode
    };

    return this.httpClient.request('post', URL,
      {
        body,
        headers
      }
    );

  }


}
